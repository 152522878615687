/** @jsx jsx */
import { Flex, Spinner, jsx } from "theme-ui"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { features, parking_features, parking_data } from "../util/const"
import { GoLocation } from "react-icons/go"
import { BiBus } from "react-icons/bi"
import ReactStarsRating from "react-awesome-stars-rating"
import { useEffect, useState } from "react"

const STATUS = {
  TO_RESERVE: "Rezerwuj",
  LOADING: "Sprawdzanie",
  NOT_AVAILABLE: "Niedostępny",
  NO_PLACE: "Brak miejsc",
}

const PostCard = ({
  data,
  parkingData,
  queryString,
  isParkViaLoadingStatuses,
  isParklotLoading,
}) => {
  const [status, setStatus] = useState(STATUS.TO_RESERVE)

  const isParkVia = data.frontmatter.isParkVia || false
  const parkData = parkingData?.find(
    pData => pData.slug === data.frontmatter.slug,
  )
  const parking =
    parking_data[process.env.GATSBY_PARKING_SLUG] &&
    parking_data[process.env.GATSBY_PARKING_SLUG][data.frontmatter.slug] !=
      null &&
    parking_data[process.env.GATSBY_PARKING_SLUG][data.frontmatter.slug]

  const fromAirport = parking?.fromAirport ?? ""
  const transfer = parking?.transfer ?? ""
  const price = parking?.price ?? ""
  const days = parking?.days ?? ""
  const excludedFeatures =
    parking_data[process.env.GATSBY_PARKING_SLUG]?.excludedFeatures ?? []
  const customFeatures = parking?.customFeatures ?? []

  const checkoutUrl = parkData?.checkoutUrl ?? ""

  useEffect(() => {
    setStatus(getStatus())
  }, [
    parkData,
    parkingData,
    isParkViaLoadingStatuses,
    isParkViaLoadingStatuses?.[data.frontmatter.slug.replace("/", "")],
  ])

  const getReservationLink = (slug, queryString, isParkVia) => {
    if (isParkVia) {
      return (
        checkoutUrl || `https://www.parkvia.com/pl-PL/parking-lotnisko${slug}`
      )
    }
    let link = `https://www.parklot.pl/pl/${process.env.GATSBY_PARKING_SLUG}${slug}/`
    link += queryString ? "reservation/" : ""
    link += `${
      queryString ? "?" + queryString + "&" : "?"
    }utm_source=travellead&utm_medium=cps&utm_campaign=1174-baliceparking.pl&a_aid=1174&a_cid=a988c2f2&chan=main_search`
    return link
  }

  const getStatus = () => {
    if (
      (isParkVia &&
        isParkViaLoadingStatuses?.[data.frontmatter.slug.replace("/", "")]) ||
      (!isParkVia && isParklotLoading)
    ) {
      return STATUS.LOADING
    }

    return parkData == undefined && parkingData !== undefined
      ? STATUS.NOT_AVAILABLE
      : parkData?.isAvailable === false
      ? STATUS.NO_PLACE
      : STATUS.TO_RESERVE
  }

  const reservationLink = getReservationLink(
    data.frontmatter.slug,
    queryString,
    isParkVia,
    parkData?.checkoutUrl,
  )

  return (
    <Flex
      className="post-card"
      sx={{
        bg: "cardBg",
      }}
    >
      {data.frontmatter.featuredImage ? (
        <Link to={data.frontmatter.slug}>
          <GatsbyImage
            image={
              data.frontmatter.featuredImage.childImageSharp.gatsbyImageData
            }
            alt={data.frontmatter.title + " - głowne zdjęcie parkingu"}
            title={`Główne zdjęcie parkingu ${data.frontmatter.title}`}
            className="featured-image"
          />
          {parkData?.rating && (
            <div
              key={`parking-rating-${parkData.rating}`}
              className="parking-rating"
            >
              <ReactStarsRating
                className="parking-rating-stars"
                id={data.frontmatter.slug}
                isEdit={false}
                value={Number(parkData.rating.split("/")?.[0])}
              />
              {parkData?.rating}
            </div>
          )}
        </Link>
      ) : (
        ""
      )}
      <div className="post-container">
        <h3 className="title">
          <Link
            title={`Link do podstrony parkingu ${data.frontmatter.title}`}
            to={data.frontmatter.slug}
            sx={{
              variant: "links.postLink",
            }}
          >
            {data.frontmatter.title}
          </Link>
        </h3>
        <div className="post-content">
          {Object.keys(features)
            .filter(feat => !excludedFeatures.includes(features[feat]))
            .map((feature, index) => (
              <div key={index}>
                {parking_features[data.frontmatter.slug] != null &&
                parking_features[data.frontmatter.slug].includes(
                  features[feature],
                )
                  ? "✅ "
                  : "❌ "}
                {features[feature]}
              </div>
            ))}
          {customFeatures?.length === 0
            ? null
            : customFeatures.map((feat, index) => (
                <div key={"customfeatures" + index}>{feat}</div>
              ))}
        </div>
        <div className="post-content-location">
          <span className="icon">
            <GoLocation />
            <b> {fromAirport.toString().padEnd(3, ".0")} km </b>
            odległość od lotniska
          </span>
          <span className="icon transfer">
            <BiBus />
            <b> {transfer ? transfer : "0"} min </b>
            transfer na lotnisko
          </span>
        </div>
      </div>
      <div className="reservation-container">
        {status === STATUS.LOADING ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spinner
              className="reservation-spinner"
              sx={{
                color: "primary",
              }}
            >
              Sprawdzamy cenę
            </Spinner>
          </div>
        ) : (
          <div className="reservation-price bold">
            {parkData?.price ?? price}zł
          </div>
        )}
        <div className="reservation-time">
          {isParkVia &&
            (parkData?.days
              ? `za ${parkData?.days} dni`
              : !parkData
              ? `za ${days} dni`
              : "")}

          {!isParkVia && `za ${parkData?.days ?? days} dni`}
        </div>
        {status === STATUS.LOADING ? null : (
          <a
            target="_blank"
            rel="noreferrer noopener sponsored"
            href={reservationLink}
            title={`Link do rezerwacji parkingu ${data.frontmatter.title}`}
            className={`button reservation-btn ${
              (parkData == undefined && parkingData !== undefined) ||
              parkData?.isAvailable === false
                ? "not-available"
                : ""
            }`}
            disabled={
              (parkData == undefined && parkingData !== undefined) ||
              parkData?.isAvailable === false
            }
            sx={{
              variant: "variants.button",
            }}
            onClick={() => {
              // eslint-disable-next-line no-undef
              if (window && window.gtag && typeof window.gtag === "function") {
                // eslint-disable-next-line no-undef
                window.gtag("event", "reservation-click", {
                  event_category: data.frontmatter.slug,
                  event_label: queryString,
                  value: Number(price),
                })
                if (isParkVia) {
                  // eslint-disable-next-line no-undef
                  window.gtag("event", "parkvia-reservation-click", {
                    event_category: data.frontmatter.slug,
                    event_label: queryString,
                    value: Number(price),
                  })
                }
              }
            }}
          >
            {status}
          </a>
        )}
        <div className="reservation-info">🟢 Darmowe anulowanie</div>
      </div>
    </Flex>
  )
}

export default PostCard

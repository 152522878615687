import React, { useState } from "react"
import { RiArrowRightSLine } from "react-icons/ri"
import Layout from "./layout"
import SEO from "./seo"
import loadable from "@loadable/component"
import BlogListHome from "./blog-list-home"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Divider } from "theme-ui"
const SearchForm = loadable(() => import("../components/SearchForm"))

export const Homepage = ({
  frontmatter,
  data,
  descriptionComponent,
  children,
}) => {
  const parkViaParkings = data.posts.edges
    .filter(({ node }) => node.frontmatter.isParkVia)
    .map(({ node }) => node.frontmatter)

  const [parkingData, setParkingData] = useState()
  const [queryString, setQueryString] = useState()
  const [isParkViaLoadingStatuses, setIsParkViaLoadingStatuses] = useState()
  const [isParklotLoading, setIsParklotLoading] = useState()

  return (
    <Layout>
      <SEO />
      <div className="home-banner grids col-1 sm-2">
        <div>
          <h1 className="title">{frontmatter.title}</h1>
          <p
            className="tagline"
            sx={{
              color: "muted",
            }}
          >
            {frontmatter.tagline}
          </p>
          <div
            className="description"
            // dangerouslySetInnerHTML={{ __html: html }}
          >
            {descriptionComponent}
          </div>
          <Link
            to={frontmatter.cta.ctaLink}
            className="button"
            sx={{
              variant: "variants.button",
            }}
          >
            {frontmatter.cta.ctaText}
            <span className="icon -right">
              <RiArrowRightSLine />
            </span>
          </Link>
        </div>
        <div>
          <GatsbyImage
            image={data?.file?.childImageSharp.gatsbyImageData}
            alt="Zdjęcie lotniska"
            placeholder="blurred"
            className="featured-image"
          />
        </div>
      </div>
      <Divider my={4} />
      <SearchForm
        setParkingData={setParkingData}
        setQueryString={setQueryString}
        parkViaParkings={parkViaParkings}
        setIsParkViaLoadingStatuses={setIsParkViaLoadingStatuses}
        isParklotLoading={isParklotLoading}
        setIsParklotLoading={setIsParklotLoading}
      />
      <BlogListHome
        data={data.posts}
        parkingData={parkingData}
        queryString={queryString}
        isParkViaLoadingStatuses={isParkViaLoadingStatuses}
        isParklotLoading={isParklotLoading}
      />
      {children}
    </Layout>
  )
}

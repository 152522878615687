/** @jsx jsx */
import { jsx } from "theme-ui"
import { RiArrowDownLine } from "react-icons/ri"

import PostCard from "./post-card"

export default function BlogListHome(props) {
  const data = props.data
  const posts = data.edges
    .filter(edge => (edge.node.frontmatter?.doNotShowOnMainPage ? false : true))
    .map(edge => (
      <PostCard
        key={edge.node.id}
        data={edge.node}
        parkingData={props.parkingData}
        queryString={props.queryString}
        isParkViaLoadingStatuses={props.isParkViaLoadingStatuses}
        isParklotLoading={props.isParklotLoading}
      />
    ))
  return <PostMaker data={posts} />
}

const PostMaker = ({ data }) => (
  <section className="home-posts">
    <h2>
      Lista <strong>parkingów</strong>
      <span className="icon -right">
        <RiArrowDownLine />
      </span>
    </h2>
    <div className="grids col-1">{data}</div>
  </section>
)
